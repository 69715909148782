import React, { type CSSProperties, type FC, type FormEvent, useState } from 'react';

const inputStyle: CSSProperties = {
    width: '100%',
    border: '1px solid',
    borderRadius: '4px',
    padding: '12px 10px',
    boxShadow: '0px -2px 0px 0px #F4F5F9 inset, 0px 2px 0px 0px #FFF inset',
};

const textareaStyle: CSSProperties = {
    resize: 'vertical',
    minHeight: '150px',
    maxHeight: '300px',
};

// FIXME: Poprawić
export const ContactForm: FC = () => {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [errors, setErrors] = useState({ firstName: false, email: false, message: false });
    const [status, setStatus] = useState(0);

    const sendForm = (event: FormEvent): void => {
        event.preventDefault();

        const result = {
            firstName: firstName.length < 2,
            email: email.length < 5 || !email.includes('@') || !email.includes('.'),
            message: message.length < 10,
        };

        setErrors(result);

        if (!result.firstName && !result.email && !result.message) {
            setFirstName('');
            setEmail('');
            setMessage('');

            setStatus(1);
        } else {
            setStatus(0);
        }
    };

    return (
        <div className="box bg-gray">
            <h1 className="fs-28">Trimite-ne un mesaj</h1>

            <form onSubmit={sendForm} className="d-grid g-13 mt-24">
                <div className="d-grid g-13">
                    <label htmlFor="first-name" className="fs-14 c-blue">
                        Prenume
                    </label>

                    <input
                        style={{ ...inputStyle, borderColor: errors.firstName ? '#ff0000' : '#DDE3E8' }}
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        type="text"
                        id="first-name"
                        name="first-name"
                        placeholder="Introdu numele tău"
                    />
                </div>

                <div className="d-grid g-13">
                    <label htmlFor="e-mail" className="fs-14 c-blue">
                        Adresă de email
                    </label>

                    <input
                        style={{ ...inputStyle, borderColor: errors.email ? '#ff0000' : '#DDE3E8' }}
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        id="e-mail"
                        name="e-mail"
                        placeholder="Introdu adresa ta de email"
                    />
                </div>

                <div className="d-grid g-13">
                    <label htmlFor="message" className="fs-14 c-blue">
                        Mesaj
                    </label>

                    <textarea
                        style={{
                            ...inputStyle,
                            ...textareaStyle,
                            borderColor: errors.message ? '#ff0000' : '#DDE3E8',
                        }}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                        id="message"
                        name="message"
                        placeholder="Mesajul tău pentru Loando"
                    />
                </div>

                <button className="button blue-button">Trimite</button>
            </form>

            {status === 1 && (
                <p className="mt-16 fs-14 c-blue">
                    Mulțumim pentru mesaj! Îți vom răspunde în cel mai scurt timp posibil.
                </p>
            )}
        </div>
    );
};
