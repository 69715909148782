import React, { type CSSProperties, type FC, type HTMLAttributes, type ReactNode } from 'react';

import { Breadcrumbs } from '@/components/navigations/breadcrumbs';
import { cn } from '@/utils/class-name';

interface ContentWithHeaderProps extends HTMLAttributes<unknown> {
    theme?: 'gray' | 'blue';
    image?: ReactNode | null;
    breadcrumbs: Graphql.Breadcrumbs;
    as?: keyof JSX.IntrinsicElements;
    children: ReactNode;
}

const headerStyle: CSSProperties = {
    paddingTop: 'clamp(12px, 3.1vw, 56px)',
    paddingBottom: 'clamp(12px, 3.1vw, 56px)',
};

const headerContainerStyle: CSSProperties = {
    gap: 'clamp(10px, 2.5vw, 32px) 45px',
};

const imageBoxStyle: CSSProperties = {
    flex: '1.4 1 340px',
    overflow: 'hidden',
};

export const ContentWithHeader: FC<ContentWithHeaderProps> = ({
    as: Tag = 'header',
    theme = 'blue',
    image,
    breadcrumbs,
    style,
    className,
    children,
    ...props
}) => {
    const color = theme === 'blue' ? 'c-white' : '';

    return (
        <Tag {...props} style={{ ...headerStyle, ...style }} className={cn(`box bg-${theme}`, className)}>
            <div style={headerContainerStyle} className="container d-flex flex-wrap ai-center">
                <Breadcrumbs render={breadcrumbs} className={color} />

                <div style={{ flex: '2.7 1 400px', maxWidth: '950px' }} className={color}>
                    {children}
                </div>

                {image && (
                    <div style={imageBoxStyle} className="box bg-dark-blue text-center p-0">
                        {image}
                    </div>
                )}
            </div>
        </Tag>
    );
};
