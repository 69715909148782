import { type HeadFC } from 'gatsby';
import React, { type FC } from 'react';

import { ContentWithHeader } from '@/components/content/content-with-header';
import { ContentWithNavigation } from '@/components/content/content-with-navigation';
import { AppHead } from '@/components/head';
import { ContactForm } from '@/components/sections/contact/contact-form';

// FIXME: Poprawić
const ContactPage: FC = () => {
    return (
        <>
            <ContentWithHeader
                breadcrumbs={[{ name: 'Contactează Loando' }]}
                style={{ marginBottom: 'clamp(18px, 5.6vw, 72px)' }}
            >
                <h1 className="fs-56">Contactează Loando</h1>

                <p style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }} className="fs-24">
                    Vei primi informații clare și recomandări personalizate despre credite rapide, împrumuturi nebancare
                    și alte soluții financiare. Completează formularul - îți vom răspunde cât mai curând posibil.
                </p>
            </ContentWithHeader>

            <ContentWithNavigation
                className="container mt-small-72"
                left={
                    <>
                        <h2>Informații de contact Loando.ro</h2>

                        <div className="d-flex mt-32 g-13">
                            <img width="40" height="40" src="/assets/contact/address.svg" alt="" />

                            <div>
                                <p>Adres:</p>
                                <p>Strada Dej nr. 65, 012284 București, România</p>
                            </div>
                        </div>

                        <div className="d-flex mt-32 g-13">
                            <img width="40" height="40" src="/assets/contact/e-mail.svg" alt="" />

                            <div>
                                <p>Adresă de email:</p>
                                <a href="mailto:contact@loando.ro" className="link fs-24 c-blue">
                                    contact@loando.ro
                                </a>
                            </div>
                        </div>

                        <div className="d-flex mt-32 g-13">
                            <img width="40" height="40" src="/assets/contact/firm-name.svg" alt="" />

                            <div>
                                <p>
                                    Denumirea companiei este rezervată conform dovezii de disponibilitate și rezervare
                                    emisă de ORC-TB sub nr. 144741 / 18.04.2018.
                                </p>
                            </div>
                        </div>

                        <div className="d-flex mt-32 g-13">
                            <img width="40" height="40" src="/assets/contact/workhours.svg" alt="" />

                            <div>
                                <p>Adresă de email:</p>
                                <p>Luni - Vineri: 08:30 - 20.00</p>
                            </div>
                        </div>
                    </>
                }
                right={<ContactForm />}
            />
        </>
    );
};

export const Head: HeadFC = ({ location: { pathname } }) => {
    return (
        <AppHead
            index
            title="Contactati-ne pentru orice nelamurire"
            description="Pentru orice nelamurire legata de imprumuturi rapide ne puteti contacta oricand. Va stam la dispozitie."
            canonical={pathname}
            // ogImage={{ id: page.id, type: 'pages' }}
        />
    );
};

export default ContactPage;
