import React, { type FC, type ReactNode } from 'react';

import { Favicon } from '@/components/head/favicon';
import { OpenGraph } from '@/components/head/seo/open-graph';
import { site } from '@/utils/site';

interface AppHeadProps {
    title?: string | null;
    description?: string | null;
    canonical?: string;
    index?: boolean;
    ogImage?: { type: 'pages' | 'articles' | 'firms'; id: string };
    children?: ReactNode;
}

export const AppHead: FC<AppHeadProps> = ({ title, description, canonical, index, ogImage, children }) => {
    const fullTitle = title ? title + site.separator + site.title : site.title;

    return (
        <>
            <title>{fullTitle}</title>

            {description && <meta name="description" content={description} />}
            <meta name="robots" content={index ? 'index, follow' : 'noindex, nofollow'} />

            {index && canonical && <link rel="canonical" href={site.url + canonical} />}
            <OpenGraph title={fullTitle} description={description} canonical={canonical} ogImage={ogImage} />
            <Favicon />

            {children}
        </>
    );
};
