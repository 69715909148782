import React, { type CSSProperties, type FC, type HTMLAttributes, type ReactNode } from 'react';

import { Html } from '@/components/content/html';
import { cn } from '@/utils/class-name';

interface ContentWithNavigationProps extends HTMLAttributes<unknown> {
    left: ReactNode;
    right: ReactNode;
}

const wrapperStyle: CSSProperties = {
    gap: '32px clamp(31px, 3vw, 56px)',
};

const rightColumnStyle: CSSProperties = {
    flex: '1 1 335px',
};

const leftColumnStyle: CSSProperties = {
    flex: '3 1 560px',
};

export const ContentWithNavigation: FC<ContentWithNavigationProps> = ({ left, right, style, className, ...props }) => {
    return (
        <div {...props} style={{ ...wrapperStyle, ...style }} className={cn('d-flex flex-wrap', className)}>
            <aside style={rightColumnStyle} className="d-flex flex-column g-16 order-lg-last">
                {right}
            </aside>

            {typeof left === 'string' ? (
                <Html style={leftColumnStyle}>{left}</Html>
            ) : (
                <div style={leftColumnStyle}>{left}</div>
            )}
        </div>
    );
};
