import React, { type FC } from 'react';

import { site } from '@/utils/site';

interface OpenGraphProps {
    title: string;
    description?: string | null;
    canonical?: string;
    ogImage?: { type: 'pages' | 'articles' | 'firms'; id: string };
}

export const OpenGraph: FC<OpenGraphProps> = ({ title, description, canonical, ogImage }) => {
    return (
        <>
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:site_name" content={site.name} />
            <meta property="og:locale" content={site.lang.meta} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content={site.name} />
            <meta name="twitter:title" content={title} />

            {description && (
                <>
                    <meta property="og:description" content={description} />
                    <meta name="twitter:description" content={description} />
                </>
            )}

            {ogImage && (
                <>
                    <meta property="og:image" content={`${site.url}/social-media/${ogImage.type}/${ogImage.id}.png`} />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="675" />
                </>
            )}

            {canonical && <meta property="og:url" content={site.url + canonical} />}
        </>
    );
};
